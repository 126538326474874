import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import RoutersConfig from './route/route'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

interface configRoute {
  path: string,
  element: ReactElement
}

root.render(
  <Router>
    <Routes>
      {RoutersConfig.map((item:configRoute, index) => {
        return <Route  path={item.path} element={item.element} key={index+'route'} />
      })}
    </Routes>
  </Router>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
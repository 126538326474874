
import {App} from '../App'
import Rating from '../rating/rating'
import Paymend from '../paymend/paymend'
const routes = [
    { path: '/', element: <Paymend />},
    { path: '/rating', element: <Rating /> },
    { path: '/Index', element: < App/> },

]
export default routes

import { useState, useEffect } from 'react';
import { Rate, hooks, ImagePreview } from 'react-vant';
import { useLocation,useNavigate } from 'react-router-dom'
import { apiOrderStatus } from '../apiConfig/apiConfig'
import { httpImg, getCommentPage, getUrlParams, setTitle } from '../hook/hook'

function Rating() {
    setTitle('游呵管家-邀请下单')
  const navigate = useNavigate()
    const [value, setValue] = useState<number>(0)  //默认评分
    const [ratingText, setRatingText] = useState<string>('')   //评分值
    const [allData, setAllData] = useState<any>({})
    const location = useLocation()
    useEffect(() => {
        getOrderStatus()
        getCommentPage(getUrlParams(location.search).order_sn).then(res => {
            setAllData(res.data)
            setValue(res.data.comment_score)
        })
    }, [])
    async function getOrderStatus() {
        const res: any = await apiOrderStatus({ order_sn: getUrlParams(location.search).order_sn })
        if (res.code == 1) {
          if (res.data.is_pay == 1 && res.data.is_comment == 0) {
            navigate(`/Index?order_sn=${getUrlParams(location.search).order_sn}`)
          } else if (res.data.is_pay == 1 && res.data.is_comment == 1) {
            navigate(`/rating?order_sn=${getUrlParams(location.search).order_sn}`)
          }else{
            navigate(`/?order_sn=${getUrlParams(location.search).order_sn}`)
          }
        }
      }
    hooks.useUpdateEffect(() => {
        switch (value) {
            case 0:
                setRatingText('')
                break;
            case 1:
                setRatingText('很差')
                break;
            case 2:
                setRatingText('差')
                break;
            case 3:
                setRatingText('一般')
                break;
            case 4:
                setRatingText('满意')
                break;
            default:
                setRatingText('非常满意')
                break;
        }
    }, [value])
    const handClickImagePreview = (index: number) => {
        ImagePreview.open({
            images: allData.comment_images.split(','),
            startPosition: index,
        })
    }
    return (
        <div className='rating_container'>
            <header>
                <div className='server_rating'>
                    <div className='header_left'>
                        <span>服务评价</span>
                        <Rate value={value} size='24' color='#249856' voidColor='#d8d8d8' readonly />
                    </div>
                    <span className='header_right'>{ratingText}</span>
                </div>
            </header>
            <div className='textarea_box'>
                <p className='textarea_input'>
                    {allData.comment_content}
                </p>
               
                {
                    allData?.comment_images == '' ? '' :
                        <div className='rating_image_box' >
                            {allData?.comment_images?.split(',').map((item: any, index: any) => {
                                return <img src={item} alt="" key={index + 'rating_img'} onClick={() => handClickImagePreview(index)} />
                            })}
                        </div>
                }

            </div>
            <div className='customer_service'>
                <p className='qrcode_service'>客服二维码</p>
                <p className='qrcode_service1'>长按添加客服，快来下单吧</p>
                <div className='qrcode_box'>
                    <p>
                        <img className='qrcode_img' src={httpImg(allData.kf_qrcode)} alt="" />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Rating;

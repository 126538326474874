import React, { useState } from 'react';
import { Rate, hooks } from 'react-vant';



interface Propstype {
    handChangeValue: (num: number) => void; // 向父组件返回子组件的数据,必选
    
}
const Header: React.FC<Propstype> = ({handChangeValue}) => {
    const [value, setValue] = useState<number>(0)  //默认评分
    const [ratingText, setRatingText] = useState<string>('')   //评分值
    hooks.useUpdateEffect(() => {
        switch (value) {
            case 0:
                setRatingText('')
                break;
            case 1:
                setRatingText('很差')
                break;
            case 2:
                setRatingText('差')
                break;
            case 3:
                setRatingText('一般')
                break;
            case 4:
                setRatingText('满意')
                break;
            default:
                setRatingText('非常满意')
                break;
        }
    }, [value])
    const handChangeRating = (e: number) => {
        handChangeValue(e)
        setValue(e)
    }

    return (
        <header>
            <div className='server_rating'>
                <div className='header_left'>
                    <span>服务评价</span>
                    <Rate value={value} onChange={handChangeRating} size='24' color='#249856' voidColor='#d8d8d8' />
                </div>
                <span className='header_right'>{ratingText}</span>
            </div>
        </header>
    );
}

export default Header;


//获取url参数并转换为对象
import { Toast } from 'react-vant';
import { apiCommentPage } from '../apiConfig/apiConfig'

const getUrlParams = (query: string): Record<string, string> => (
  Array.from(new URLSearchParams(query)).reduce((p, [k, v]) => Object.assign({}, p, { [k]: p[k] ? (Array.isArray(p[k]) ? p[k] : [p[k]]).concat(v) : v }), {} as Record<string, string>)
);

const setTitle = (title: string) => {
  document.title = title
}
const httpImg = (url: string) => {
  return 'https://admin.youheguanjia.com' + url
}



const getCommentPage = async (order_sn: string) => {
  const res: any = await apiCommentPage({ order_sn: order_sn })
  let data = res
  return data
}
const strategyMode = (value: any) => { // 用形参匹配
  let obj: any = ''
  switch (value) {
    case 0:
      obj = 'h'
      break;
    case 1:
      obj = 'm²'
      break;
    default:
      obj = '次'
      break
  }
  return obj
}
const strategyMode1 = (value: any) => { // 用形参匹配
  let obj: any = ''
  switch (value) {
    case 0:
      obj = '服务时长'
      break;
    case 1:
      obj = '服务面积'
      break;
    default:
      obj = '服务次数'
      break
  }
  return obj
}
const isWeiXin = () => {
  var ua: any = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}
if (isWeiXin()) {
  console.log(" 是来自微信内置浏览器")
} else {
  console.log("不是来自微信内置浏览器")
}

export {
  isWeiXin,
  getUrlParams,
  setTitle,
  httpImg,
  strategyMode,
  strategyMode1,
  getCommentPage
}
import { useEffect, useState } from 'react'
import { setTitle, getUrlParams, isWeiXin } from '../hook/hook'
import { useNavigate, useLocation } from 'react-router-dom'
import { apiPaymend, apiOrderStatus } from '../apiConfig/apiConfig'
import isWeiXinImg from '../static/weixin_arrow.svg'
function Paymend() {
    setTitle('订单支付')
    const location = useLocation()
    const navigate = useNavigate()
    const [params, setParams] = useState<any>()
    const [apiData, setApiData] = useState<any>({})
    const handClickPaymend = () => {
        getPaymend()
    }
    async function getPaymend() {
        const res: any = await apiPaymend({ order_sn: params.order_sn })
        if (res.code == 1) {
            window.location.href = res.data.mweb_url + '&redirect_url=' + encodeURIComponent(window.location.href)
        }
    }
    async function getOrderStatus() {
        const res: any = await apiOrderStatus({ order_sn: getUrlParams(location.search).order_sn })
        if (res.code == 1) {
            setApiData(res.data)
            if (res.data.is_pay == 1 && res.data.is_comment == 0) {
                navigate(`/Index?order_sn=${getUrlParams(location.search).order_sn}`)
            } else if (res.data.is_pay == 1 && res.data.is_comment == 1) {
                navigate(`/rating?order_sn=${getUrlParams(location.search).order_sn}`)
            }
        }
    }
    useEffect(() => {
        getOrderStatus()
        setParams(getUrlParams(location.search))
    }, [])
    return <div>
        {isWeiXin()? <div className='is_weixin'>
            <p>请点击右上角...<br />选择浏览器打开</p>
            <img src={isWeiXinImg} alt="" />
        </div>:''}
       
        <div className='paymend_container'>
            <p className='paymend_title'>待支付金额</p>
            <h1 className='paymend_money'>￥{apiData.end_price}</h1>
            <div className='paymend_line'></div>
            <div className='paymend_description'>
                <span>客户姓名</span>
                <span>{apiData.name}</span>
            </div>
            <div className='paymend_description'>
                <span>订单地址</span>
                <span>{apiData.address}</span>
            </div>
        </div>
        <div className='footer' onClick={handClickPaymend}>
            <span>去支付</span>
        </div>
    </div>
}

export default Paymend
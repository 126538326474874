import React, {
    useEffect, useState,
    useContext
} from 'react'
import type { UploaderFileListItem } from 'react-vant';
import { Uploader } from 'react-vant';
import { useLocation } from 'react-router-dom'
import LeaderImg from '../static/leaderImg.svg'
import { ChildrenContext } from '../App'
import { apiUploadToolImg } from '../apiConfig/apiConfig'
import { httpImg, strategyMode1, strategyMode, getUrlParams } from '../hook/hook'
import dayjs from 'dayjs'
interface previewDataType {
    url: string,
    status: string,
    name: string
}
interface deleteType{
    name:string,
    index:number
}
const Main = (props:any) => {
    const {setTextArea,setImage} =props
    const baseval:any = useContext(ChildrenContext)
    const location = useLocation()
    const [previewData, setPreviewData] = useState<any>([])
    const [textareaValue, setTextareaValue] = useState<string>('')  //文本域值
    const [value, setValue] = useState<UploaderFileListItem[]>(previewData);
    const [showUpload, setShowUpload] = useState<boolean>(true)
    const afterRead = (file: any, { index }: any) => {
        let url = new FormData()
        url.append('file', file.file);
        getUploadToolImg(file, index)
    };
    useEffect(() => {
        if (value?.length >= 3) {
            setShowUpload(false)
        }
    }, [value])
    const handClickDelete=(detail:any)=>{
        console.log(detail)
        const newList=[...previewData]
        newList.splice(detail.index,1)
        setPreviewData(newList)
    }
    const getUploadToolImg = async (file: any, index: any) => {
        const res: any = await apiUploadToolImg({ order_sn: getUrlParams(location.search).order_sn, file: file.file })
        if (res.code == 1) {
            let data: previewDataType = {
                url: httpImg(res.data.url),
                status: 'done',
                name: ''
            }
            let newList: any = []
            newList.push(data)
            setPreviewData((previewData: any) => ([...previewData, ...newList]))
        }
    }
    useEffect(()=>{setImage(previewData)},[previewData])
    const handTextarea = (e: any) => {
        setTextArea(e.target.value)
        setTextareaValue(e.target.value)
    }
    return (
        <div>
            <div className='textarea_box'>
                <textarea className='textarea_input' onInput={handTextarea} value={textareaValue} placeholder='请填写评价内容'></textarea>
                <Uploader
                    afterRead={afterRead}
                    showUpload={showUpload}
                    value={value}
                    onChange={setValue}
                    onDelete={(detail)=>handClickDelete(detail)}
                />
            </div>
            <div className="main_container">
                <p className="main_title">服务信息</p>
                <div className="main_server_list">
                    <ul>
                        {
                            baseval?.users?.map((item: any, index: number) => {
                                return <li className="list_content" key={index + 'list'}>
                                    <div className="list_content_left">
                                        <div className='list_content_tags orange'>{item.label}</div>
                                        <img className="avatar_img" src={httpImg(item.avatar)} alt="" />
                                        <div className="list_content_info" onClick={()=>console.log(item)}>
                                            <p>{item.username}</p>
                                            <span >{strategyMode1(baseval?.service_type)}:{item.work_area}{strategyMode(baseval?.service_type)}</span>
                                        </div>
                                    </div>
                                    {item.is_leader == 1 ? <img className="leader_img" src={LeaderImg} alt="" /> : ''}
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="main_container">
                <div className="order_title">
                    <p className="main_title">订单信息</p>
                    <p className="order_info_money">￥{baseval.price}</p>
                </div>
                <p className="line"></p>
                <div className="order_info">
                    <div className="order_info_content">
                        <p>客户姓名</p>
                        <span>{baseval.name}</span>
                    </div>
                    <div className="order_info_content">
                        <p>服务时间</p>
                        <span>{dayjs(baseval.service_start_time * 1000).format('MM月DD日 hh:mm')} ～ {dayjs(baseval.service_end_time * 1000).format('MM月DD日 hh:mm')}</span>
                    </div>
                    <div className="order_info_content">
                        <p>服务类型</p>
                        <span>{baseval.service_type_name}</span>
                    </div>
                    <div className="order_info_content">
                        <p>{strategyMode1(baseval.service_type)}</p>
                        <span>{baseval.service_num}{strategyMode(baseval.service_type)}</span>
                    </div>
                    <div className="order_info_content">
                        <p>详细地址</p>
                        <span>{baseval.province}{baseval.city}{baseval.address}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main 
import React, { useMemo, useState, useEffect, createContext } from 'react';
import Header from './header/header';
import Main from './main/main'
import './App.css';

import { useNavigate, useLocation } from 'react-router-dom'
import { apiSubComment, apiOrderStatus } from './apiConfig/apiConfig'
import { getUrlParams, setTitle, getCommentPage } from './hook/hook'
import { Toast } from 'react-vant';
export const ChildrenContext = createContext('')
export const App = () => {
  setTitle('评价')
  const navigate = useNavigate()
  const location = useLocation()
  const [value, setValue] = useState<number>(0)
  const [textArea, setTextArea] = useState<string>('')
  const [params, setParams] = useState<any>({})
  const [allData, setAllData] = useState<any>({})
  const [image, setImage] = useState<any>()

  useEffect(() => {
    getOrderStatus()
    getCommentPage(getUrlParams(location.search).order_sn).then(res => {
      setAllData(res.data)
    })
    setParams(getUrlParams(location.search))
  }, [])
  async function getOrderStatus() {
    const res: any = await apiOrderStatus({ order_sn: getUrlParams(location.search).order_sn })
    if (res.code == 1) {
      if (res.data.is_pay == 1 && res.data.is_comment == 0) {
        navigate(`/Index?order_sn=${getUrlParams(location.search).order_sn}`)
      } else if (res.data.is_pay == 1 && res.data.is_comment == 1) {
        navigate(`/rating?order_sn=${getUrlParams(location.search).order_sn}`)
      }else{
        navigate(`/?order_sn=${getUrlParams(location.search).order_sn}`)
      }
    }
  }
  const handClickRating = () => {
    getSubComment()
  }
  async function getSubComment() {
    let newList = image
    let arr:any=[]
    newList.forEach((item: any, index: number) => {
      console.log(item)
      arr.push(item.url)
    })
    let data = {
      order_sn: params.order_sn,
      comment_score: value,
      comment_content: textArea,
      comment_images:arr.join(',')
    }
    const res: any = await apiSubComment(data)
    if (res.code == 1) {
      navigate(`/rating?order_sn=${getUrlParams(location.search).order_sn}`)
    } else {
      Toast.fail(res.msg)
    }
  }
  const Main2 = useMemo(() => <Main setTextArea={setTextArea} setImage={setImage} />, [allData])

  return (
    <div className="container">
      <ChildrenContext.Provider value={allData}>
        <div className='main'>
          <Header handChangeValue={(num) => { setValue(num) }} />
          {Main2}
        </div>
      </ChildrenContext.Provider>
      <div className='footer' onClick={handClickRating}>
        <span>立即评价</span>
      </div>
    </div>

  );
}


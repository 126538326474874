

import request from '../serveice'

const apiConfig = (url: string, method: string, data: any,) => {
  return request({
    url,
    method,
    data,
    interceptors: {
      requestInterceptors(res: any) {
        console.log('接口请求拦截')
        return res
      },
      responseInterceptors(result: any) {
        console.log('接口响应拦截')
        return result
      },
    },
  })
}




function apiSubComment(parmas: any) {
  return apiConfig('/api/comment/subComment', 'POST', parmas)
}

function apiPaymend(parmas: any) {
  return apiConfig('/api/comment/pay', 'POST', parmas)
}

function apiOrderStatus(parmas: any) {
  return apiConfig('/api/comment/orderStatus', 'POST', parmas)
}

function apiUploadToolImg(parmas: any) {
  return apiConfig('/api/common/upload', 'POST', parmas)
}

function apiCommentPage(parmas: any) {
  return apiConfig('/api/comment/commentPage', 'POST', parmas)
}
export {
  apiSubComment,
  apiPaymend,
  apiOrderStatus,
  apiCommentPage,
  apiUploadToolImg
}